<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

import LinkBar from '../components/LinkBar.vue'
import ImageCol from '@/components/ImageCol.vue';
document.querySelector("body").classList.add('bg-gradient-primary')

const salesLink = `mailto:${store.translate('contact_sales_email')}?subject=${store.translate('contact_sales_subject')}`
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <!-- Nested Row within Card Body -->
                    <div class="row min-height">
                        <ImageCol></ImageCol>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-4">
                                        {{ store.translate("proxmox_backup_service") }}
                                    </h1>
                                </div>
                                <br />
                                <p>
                                    {{ store.translate("text_proxmox_backup") }}
                                </p>
                                <p>
                                    {{ store.translate("headline_product_list") }}
                                </p>
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col-2 col-md-1">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="col">
                                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                Dynamischer Tarif
                                            </div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800 max-w400">
                                                {{ store.translate("dynamic_price_text") }}
                                            </div>
                                            <div class="text-s max-w400">
                                                {{ store.translate("dynamic_space_text") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col-2 col-md-1">
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div class="col">
                                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                Volumen Tarif
                                            </div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800 max-w400">
                                                {{ store.translate("volume_price_text") }}
                                            </div>
                                            <div class="text-s max-w400">
                                                {{ store.translate("fixed_price_text") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                                <p>
                                    {{ store.translate("short_claim") }}
                                </p>
                                <router-link to="/register">
                                    <button class="btn btn-primary btn-user btn-block">
                                        Registrieren
                                    </button>
                                </router-link>
                                <div class="center p-4">
                                  <a class="small" :href="salesLink">
                                    {{ store.translate("contact_sales_label") }}
                                  </a>
                                </div>
                                <hr>
                                <LinkBar></LinkBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
