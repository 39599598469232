<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

function sendContactMessage() {
    store.sendContactMessage()
}
</script>

<template>
    <div class="contact">
        <div class="form-group">
            <label>{{ store.translate("name") }}</label>
            <input type="text" class="form-control form-control-user" v-model="store.fullname">
        </div>
        <div class="form-group">
            <label>{{ store.translate("email") }}</label>
            <input type="text" class="form-control form-control-user" v-model="store.email">
        </div>
        <div class="form-group">
            <label>{{ store.translate("message") }}</label>
            <textarea class="form-control form-control-user" v-model="store.form_message"></textarea>
        </div>
        <input type="hidden" name="nospam" id="nospam">
    </div>
    <p>
        {{ store.message }} {{ store.errorMessage }}
    </p>
    <button class="btn btn-primary btn-user btn-block" :disabled="store.loading" @click="sendContactMessage"
        @submit.prevent>
        {{ store.translate("button_send") }}
    </button>
</template>

<style scoped>
h1 {
    font-weight: 500;
    font-size: 2.6rem;
    position: relative;
    top: -10px;
}

h3 {
    font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
    text-align: center;
}

textarea {
    height: 120px;
}

@media (min-width: 1024px) {
    .greetings h1,
    .greetings h3 {
        text-align: left;
    }
}
</style>
