import './assets/main.css'
import './assets/style.css'

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import * as Sentry from '@sentry/vue'

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

/* Load Sentry (if enabled) */
let sentryEnabled = import.meta.env.VITE_SENTRY_ENABLED.toLowerCase() === 'true'
if (sentryEnabled) {
  let sentryErrorReportEnabled =
    import.meta.env.VITE_SENTRY_REPORT_ERROR_ENABLED.toLowerCase() === 'true'
  let sentryUserReportEnabled =
    import.meta.env.VITE_SENTRY_REPORT_USER_ENABLED.toLowerCase() === 'true'
  let sentryTransmitUserDetails =
    import.meta.env.VITE_SENTRY_TRANSMIT_USER_DETAILS.toLowerCase() === 'true'

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    beforeSend(event) {
      /* Show error reporting modal, if enabled */
      if (event.exception && event.event_id && sentryErrorReportEnabled) {
        let sentryUsername = 'Anonymous'
        let sentryUserEmail = 'anonymous@example.org'
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: { name: sentryUsername, email: sentryUserEmail },
        })
      }
      /* Drop user infos for privacy, if not disabled */
      if (!sentryTransmitUserDetails && event.user) {
        delete event.user.email
        delete event.user.username
      }
      return event
    },
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        unmask: ['.sentry-unmask'],
        unblock: ['.sentry-unblock'],
        ignore: ['.sentry-ignore', '[data-sentry-ignore]'],
      }),
      Sentry.browserProfilingIntegration(),
      sentryUserReportEnabled &&
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        enableScreenshot: true,
        showBranding: false,
        id: 'feedback-integration',
        showName: false,
        showEmail: false,
        messageLabel: 'Beschreibung',
        isRequiredLabel: '(Erforderlich)',
        addScreenshotButtonLabel: 'Screenshot hinzufügen',
        removeScreenshotButtonLabel: 'Screenshot entfernen',
        cancelButtonLabel: 'Abbrechen',
        confirmButtonLabel: 'Bestätigen',
        submitButtonLabel: 'Bug Report absenden',
        triggerLabel: 'Fehler melden',
        formTitle: 'Fehler melden',
        messagePlaceholder: 'Was ist der Fehler? Was haben Sie erwartet?',
        successMessageText: 'Vielen Dank für Ihren Bericht!',
      }),
    ].filter(Boolean),

    // Tracing
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: import.meta.env
      .VITE_SENTRY_TRACE_PROPAGATION_TARGETS,
    // Session Replay
    environment: import.meta.env.VITE_SENTRY_ENV,
    replaysSessionSampleRate: import.meta.env
      .VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: import.meta.env
      .VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  })
}

app.use(pinia)
app.use(router)

app.mount('#app')
