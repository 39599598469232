<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

function sendLogin() {
    store.sendLogin()
}
</script>

<template>
    <div class="user">
        <div class="form-group">
            <input type="text" class="form-control form-control-user" v-model="store.email" placeholder="E-Mail">
        </div>
        <div class="form-group">
            <input type="password" class="form-control form-control-user" v-model="store.password"
                placeholder="Passwort">
        </div>
    </div>
    <button class="btn btn-primary btn-user btn-block" :disabled="store.loading" @click="sendLogin" @submit.prevent>
        {{ store.translate("send") }}
    </button>
    <br /><br />
    <p>
        {{ store.loginMessage }}
    </p>
</template>

<style scoped>
h1 {
    font-weight: 500;
    font-size: 2.6rem;
    position: relative;
    top: -10px;
}

h3 {
    font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
    text-align: center;
}

@media (min-width: 1024px) {

    .greetings h1,
    .greetings h3 {
        text-align: left;
    }
}
</style>
