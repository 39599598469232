<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <div v-if="store.company?.company_number">
        <h5>
            {{ store.translate("cancellation") }}:
        </h5>
        <p>
            {{ store.translate("cancel_text") }}
        </p>
        <button class="btn btn-primary btn-user btn-block" @click="() => { store.cancelFormText() }">
            {{ store.translate("cancellation") }}
        </button>
    </div>
</template>

<style scoped></style>
