<script setup>
import Topbar from '../components/Topbar.vue'
import Sidebar from '../components/Sidebar.vue'
import LoggedIn from '@/components/LoggedIn.vue'
import ContactForm from '../components/ContactForm.vue'

import { useMainStore } from '@/stores/main'
const store = useMainStore()
store.getUser()
</script>

<template>
    <LoggedIn></LoggedIn>
    <div id="wrapper">
        <Sidebar></Sidebar>
        <div id="content-wrapper" class="d-flex flex-column">

            <!-- Main Content -->
            <div id="content">
                <Topbar></Topbar>
                <div class="container-fluid">
                    <!-- Content Row -->
                    <div class="row">
                        <div class="col-xl-6 col-md-12 mb-12">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">
                                    {{ store.translate("contact") }}
                                </h1>
                            </div>
                            <p>
                                {{ store.translate("contact_infotext") }}
                            </p>
                            <p>
                                {{ store.translate("best_regards") }}
                            </p>
                            <ContactForm></ContactForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>