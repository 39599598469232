<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

store.getUser()
store.getCompany()
</script>

<template>
    <div>
        <h5>
            {{ store.translate("company_data") }}:
        </h5>
        {{ store.translate("email") }}: {{ store.user?.email }}<br />
        <div v-if="store.company?.name">
            {{ store.translate("name") }}: {{ store.company?.name }}<br />
            {{ store.translate("company_number") }}: {{ store.company?.company_number }}<br />
            {{ store.translate("street") }}: {{ store.company?.street }}<br />
            {{ store.company?.postcode }} {{ store.company?.city }}<br />
            {{ store.translate("country") }}: {{ store.company?.country }}<br />
            {{ store.translate("ustid") }}: {{ store.company?.ustid }}
        </div>
    </div>
</template>

<style scoped></style>
