import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import RegisterCompany from '../views/RegisterCompany.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import NewPassword from '../views/NewPassword.vue'
import Dashboard from '../views/Dashboard.vue'
import Company from '../views/Company.vue'
import Tutorial from '../views/Tutorial.vue'
import Waiting from '../views/Waiting.vue'
import Contact from '../views/Contact.vue'
import Notification from '@/views/Notification.vue'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'index',
            component: Index
        },
        {
            path: '/login/:message?',
            name: 'login',
            component: Login
        },
        {
            path: '/register',
            name: 'register',
            component: RegisterCompany
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard
        },
        {
            path: '/company',
            name: 'company',
            component: Company
        },
        {
            path: '/tutorial',
            name: 'tutorial',
            component: Tutorial
        },
        {
            path: '/reset',
            name: 'Reset',
            component: ForgotPassword
        },
        {
            path: '/waiting',
            name: 'Waiting',
            component: Waiting
        },
        {
            path: '/notifications',
            name: 'Notification',
            component: Notification
        },
        {
            path: "/newpassword",
            name: "Password",
            component: NewPassword
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/:pathMatch(.*)*',
            component: Index
        }
    ]
})

export default router
