<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <div>
        <h5>
            {{ store.translate("notification") }}:
        </h5>
        <p>
            {{ store.translate("notification_text") }}
        </p>
        <label>
            <input type="checkbox" @click="store.toggleNotifications" v-model="store.notifications" />
            {{ store.translate("notification_checkbox") }}
        </label>
    </div>
</template>

<style scoped></style>
